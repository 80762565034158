import { RouteRecordRaw } from "vue-router";
import { modulo } from '../const'

const routes: RouteRecordRaw[] = [
  {
    path: `datos-facturacion/formulario/:clienteId`,
    name: `${modulo}.datosFacturacion.form`,
    component: () => import('./form/index')
  }
]

export default routes
