type TBody = {
  id: string;
  razonSocial: string;
  logo: string;
}

export type TClienteRaw = {
  id: string;
  razonSocial: string;
  logo: string;
}

export class Cliente {
  #id: string;
  #razonSocial: string;
  #logo: string;

  get id() {
    return this.#id
  }

  get razonSocial() {
    return this.#razonSocial
  }
  get logo() {
    return this.#logo
  }

  constructor({ id, razonSocial, logo }: TBody) {
    this.#id = id
    this.#razonSocial = razonSocial
    this.#logo = logo
  }

  toDTO() {
    return {
      id: this.#id,
      razonSocial: this.#razonSocial,
      logo: this.#logo,
    }
  }
  static toDomain({ id, razonSocial, logo }: TClienteRaw) {
    return new Cliente({
      id,
      razonSocial,
      logo,
    })
  }
}
