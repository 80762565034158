import { RouteRecordRaw } from "vue-router";
import { modulo } from '../const'
import authMiddleware from '@/middleware/auth.middleware'

const routes: RouteRecordRaw[] = [
  {
    path: `orden/listar`,
    name: `${modulo}.ordenes.listar`,
    beforeEnter: [authMiddleware],
    component: () => import('./listar/index')
  },
  {
    path: `orden/formulario`,
    name: `${modulo}.ordenes.formulario`,
    beforeEnter: [authMiddleware],
    component: () => import('./ordenForm/index')
  },
]

export default routes
